var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "outer-style", attrs: { id: "outer-style" } },
    [
      _c("h1", [_vm._v("暂时用学员列表测试课程课消统计")]),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 16 } },
            [_c("TableComponent", { ref: "table" })],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c("SliderComponent", {
                style: { marginTop: _vm.scrollTop + "px" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }