<template>
  <el-dialog :title="title" :visible.sync="dialogVisible" width="500px">
    <el-form
      :model="formInfo"
      :rules="rules"
      :inline="false"
      ref="ruleForm"
      label-width="100px"
      class="demo-form-inline"
    >
      <el-form-item label="姓名" prop="name">
        <el-input v-model="formInfo.name"></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="tel">
        <el-input v-model="formInfo.tel"></el-input>
      </el-form-item>
      <!-- <el-form-item label="登录名" prop="loginName">
        <el-input v-model="formInfo.loginName"></el-input>
      </el-form-item> -->
      <el-form-item label="密码" prop="pwd">
        <el-input type="password" v-model="formInfo.pwd"></el-input>
      </el-form-item>
      <el-form-item label="状态" prop="state">
        <el-radio v-model="formInfo.state" :label="'1'">启用</el-radio>
        <!-- <el-radio v-model="formInfo.state" label="2">备选项</el-radio> -->
        <el-radio v-model="formInfo.state" :label="'3'">禁用</el-radio>
      </el-form-item>
      <div style="text-align: right">
        <el-button type="primary" @click="submitForm('ruleForm')"
          >确定</el-button
        >
        <el-button @click="dialogVisible = false">取消</el-button>
      </div>
    </el-form>
  </el-dialog>
</template>
<script>
export default {
  props: {
    title: { type: String, default: "" },
    item: { type: Object, default: () => {} },
  },
  watch: {
    item: {
      handler(val) {
        this.formInfo = val;
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    const validatorPhone = function (rule, value, callback) {
      if (value === "") {
        callback(new Error("手机号不能为空"));
      } else if (!/^1\d{10}$/.test(value)) {
        callback(new Error("手机号格式错误"));
      } else {
        callback();
      }
    };
    return {
      dialogVisible: false,
      formInfo: {
        name: "",
        tel: "",
        pwd: "",
        state: "1"
      },
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        tel: [
          { required: false, message: "请输入手机号", trigger: "blur" },
          { required: false, validator: validatorPhone, trigger: "blur" },
        ],
        pwd: [{ required: false, message: "请输入初始密码（密码包含大小写字母、特殊符号、数字三种即可）", trigger: "blur" }],
        state: [{ required: false, message: "请输入状态", trigger: "blur" }],
      },
    };
  },
  methods: {
    async submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit("submit", this.formInfo);
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
<style lang="scss" scoped>
.el-form-item .el-form-item {
  margin-bottom: 22px;
}
</style>