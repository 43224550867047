<template>
  <div id="outer-style" class="outer-style">
    <h1>暂时用学员列表测试课程课消统计</h1>
    <el-row :gutter="20">
      <el-col :span="16">
        <TableComponent ref="table" />
      </el-col>
      <el-col :span="8">
        <SliderComponent :style="{ marginTop: scrollTop+'px' }" />
      </el-col>
    </el-row>
  </div>
</template>
<script>
import TableComponent from "./component/TableComponent";
import SliderComponent from "./component/SliderComponent";
export default {
  components: { TableComponent, SliderComponent },
  data() {
    return {scrollTop:0,};
  },
  mounted() {
    document
      .getElementById("outer-style")
      .addEventListener("scroll", this.setTop);
  },
  destroyed(){
    document
      .getElementById("outer-style")
      .removeEventListener("scroll", this.setTop);
  },
  methods: {
    setTop(e) {
      this.scrollTop = e.target.scrollTop;
    },
  },
};
</script>
<style lang="scss" scoped>
.outer-style {
  position: relative;
  height: calc(100% - 50px);
  overflow: auto;
}
</style>