<template>
  <div class="outer-slider-style">
    <div class="slider-style">
      <h3>课时总量：{{ total }}</h3>
      <el-row style="margin-top: 20px" :gutter="4">
        <el-col :span="4">
          <span
            >已分配 <span style="font-size: 12px">({{ value1 }})</span></span
          >
        </el-col>
        <el-col :span="16">
          <el-slider :max="total" v-model="value1" :readonly="true">
          </el-slider>
        </el-col>
        <el-col :span="4">
          <span
            >未分配
            <span style="font-size: 12px">({{ total - value1 }})</span></span
          >
        </el-col>
      </el-row>
      <el-row style="margin-top: 20px" :gutter="4">
        <el-col :span="4">
          <span
            >已使用 <span style="font-size: 12px">({{ value2 }})</span></span
          >
        </el-col>
        <el-col :span="16">
          <el-slider
            :max="total"
            v-model="value2"
          >
          </el-slider>
        </el-col>
        <el-col :span="4">
          <span
            >未使用<span style="font-size: 12px"
              >({{ total - value2 }})</span
            ></span
          >
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      value1: 2312,
      value2: 4112,
      total: 10000,
    };
  },
};
</script>
<style lang="scss" scoped>
.outer-slider-style {
  background-color: #fff;
}
.slider-style {
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;
  vertical-align: middle;
}
</style>